import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    HomeModule: {
      clients: "Clients",
      items: "Items",
      invoices: "Invoices",
      vat_total: "Total Vat",
      total: "Total",
      net_total: "Net Total"
    },
    LoginModule: {
      title: "Login",
    },
    DashboardModule: {
      title: "Dashboard",
    },
    UsersTypesModule: {
      title: "Users Types",
      name: "Name",
      id: "ID"
    },
    BranchesModule: {
      title: "Branches",
      name: "Name",
      printer: "Printer",
      id: "ID"
    },
    PlacesModule: {
      title: "Places",
      name: "Name",
      branch: "Branch",
      id: "ID"
    },
    SeatsModule: {
      title: "Seats",
      name: "Name",
      place: "Place",
      id: "ID"
    },
    TotalTemplatesModule: {
      title: "Total Templates",
      name: "Name",
      id: "ID",
      terms: {
        title: "Fees",
        name: "Name",
        percentage: "Percentage",
        select_type: "Choose Fee Type"
      }
    },
    BillTypesModule: {
      title: "Bills Types",
      name: "Name",
      id: "ID",
      icon: "Icon",
      vat: "Vat Template"
    },
    ItemCategoriesModule: {
      title: "Items Categories",
      name: "Name",
      id: "ID",
      icon: "Icon",
      bill_type: "Bill Type",
      printer: "Printer",
      tags: {
        name: "Name",
        title : "Tags"
      }
    },
    ItemsModule: {
      title: "Items",
      name: "Name",
      id: "ID",
      price: "Price Per Hour",
      category: "Category",
      no_price: "No Price",
      sar: "ريال"
    },
    ClientsModule: {
      title: "Clients",
      name: "Name",
      id: "ID",
      mobile: "Mobile",
      email: "Email"
    },
    UsersModule: {
      title: "Users",
      name: "Name",
      id: "ID",
      mobile: "Mobile",
      email: "Email",
      username: "Username",
      type: "User Type",
      role: "Permission",
      password: "Password"
    },
    InvoicesModule: {
      title: "Invoices",
      name: "Name",
      id: "ID",
      reference_no: "Reference No",
      invoice_no: "Invoice No",
      special_code: "Special Code",
      created_at: "Created At",
      create: "Create Invoice",
      client: "Client",
      details: "Service Description",
      project: "Project Name",
      position: "Position",
      total_hours: "Total Hours",
      unit_price: "Hour Price",
      total: "Total",
      vat: "Vat",
      total_amount: "Total Amount",
      service_date: "Service Month",
      forms: {
        enter_total_hours: "Enter Total Hours",
        total: "Total",
        hour_price: "Hour Price"
      }
    },
    VatReportModule: {
      title: "VAT Statement",
      id: "#",
      date: "Date",
      sales_total: "Total sales",
      vat_total: "Total value added tax",
      date_from: "From date",
      date_to: "To date",
    },
    PermissionsModule: {
      title: "Permissions",
      name: "Name",
      id: "ID",
      Section: "Section",
      View: "View",
      Add: "Add",
      Edit: "Edit",
      Delete: "Delete",
      sections: {
        users_types: "Users Types",
        users: "Users",
        branches: "Branches",
        places: "Places",
        seats: "Seats",
        total_templates: "Total Templates",
        bills_types: "Bills Types",
        items_categories: "Items Categories",
        items: "Items",
        clients: "Clients",
        invoices: "Invoices",
        reports: "Reports",
        permissions: "Permissions"
      }
    },
    all: "All",
    actions: "Actions",
    status: "Status",
    edit: "Edit",
    add: "Add",
    delete: "Delete",
    view: "View",
    ar: "عربي",
    en: "English",
    search: "Search ...",
    save: "Save",
    error: "Something is wrong.",
    are_you_sure: "Are you sure.",
    yes: "Yes",
    no: "No",
    choose_file: "Choose file",
    select: "Select",
    change_status: "Change Status",
    login: "Login",
    email_mobile: "Email Or Mobile .",
    password: "Password .",
    Next:"Next",
    Previous:"Previous",
    loading: "Loading",
    previous: "Previous",
    next: "Next",
    login_desc: "Sign in to the control panel",
    add_row: "Add Row",
    delete_row: "Delete Row",
    download: "Download"
  },
  ar: {
    HomeModule: {
      clients: "العملاء",
      items: "الاصناف",
      invoices: "الفواتير",
      vat_total: "اجمالي الضريبة",
      total: "الاجمالي بدون ضريبة",
      net_total: "الاجمالي بالضريبة"
    },
    LoginModule: {
      title: "تسجيل الدخول",
    },
    DashboardModule: {
      title: "الرئيسية",
    },
    UsersTypesModule: {
      title: "انواع المستخدمين",
      name: "الاسم",
      id: "الرقم التعريفي"
    },
    BranchesModule: {
      title: "الفروع",
      name: "الاسم",
      printer: "الطابعة",
      id: "الرقم التعريفي"
    },
    PlacesModule: {
      title: "الحارات",
      name: "الاسم",
      branch: "الفرع",
      id: "الرقم التعريفي"
    },
    SeatsModule: {
      title: "الجلسات",
      name: "الاسم",
      place: "الحارة",
      id: "الرقم التعريفي"
    },
    TotalTemplatesModule: {
      title: "قوالب الضرائب",
      name: "الاسم",
      id: "الرقم التعريفي",
      terms: {
        title: "الضرائب",
        name: "الاسم",
        percentage: "النسبة",
        select_type: "اختر نوع الضريبة"
      }
    },
    BillTypesModule: {
      title: "انواع الفواتير",
      name: "الاسم",
      id: "الرقم التعريفي",
      icon: "الشعار",
      vat: "قالب الضريبة"
    },
    ItemCategoriesModule: {
      title: "مجموعة الاصناف",
      name: "الاسم",
      id: "الرقم التعريفي",
      icon: "الشعار",
      bill_type: "نوع الفاتورة",
      printer: "الطابعة",
      tags: {
        name: "الاسم",
        title : "الاوسمة"
      }
    },
    ItemsModule: {
      title: "الاصناف",
      name: "الاسم",
      id: "الرقم التعريفي",
      price: "سعر الساعة",
      category: "التصنيف",
      no_price: "لم يتم تحديد سعر",
      sar: "ريال"
    },
    ClientsModule: {
      title: "العملاء",
      name: "الاسم",
      id: "الرقم التعريفي",
      mobile: "رقم الهاتف",
      email: "البريد الالكتروني"
    },
    UsersModule: {
      title: "المستخدمين",
      name: "الاسم",
      id: "الرقم التعريفي",
      mobile: "رقم الهاتف",
      email: "البريد الالكتروني",
      username: "اسم المستخدم",
      type: "نوع المستخدم",
      role: "الصلاحية",
      password: "كلمة المرور"
    },
    InvoicesModule: {
      title: "الفواتير",
      name: "الاسم",
      id: "الرقم التعريفي",
      reference_no: "الرقم المرجعي",
      invoice_no: "رقم الفاتورة",
      special_code: "ترميز الفاتورة",
      created_at: "تاريخ الفاتورة",
      create: "انشاء فاتورة",
      client: "العميل",
      details: "وصف الخدمة",
      project: "اسم المشروع",
      position: "العنصر",
      total_hours: "عدد الساعات",
      unit_price: "سعر الساعة",
      total: "الاجمالي",
      vat: "الضريبة",
      total_amount: "اجمالي الفاتورة",
      service_date: "شهر الخدمة",
      forms: {
        enter_total_hours: "ادخل عدد الساعات",
        total: "الاجمالي",
        hour_price: "سعر الساعة"
      }
    },
    VatReportModule: {
      title: "كشف ضريبة القيمة المضافة",
      id: "#",
      date: "التاريخ",
      sales_total: "اجمالي المبيعات",
      vat_total: "اجمالي ضريبة القيمة المضافة",
      date_from: "من تاريخ",
      date_to: "الي تاريخ",
    },
    PermissionsModule: {
      title: "الصلاحيات",
      name: "الاسم",
      id: "الرقم التعريفي",
      Section: "القسم",
      View: "عرض",
      Add: "اضافة",
      Edit: "تعديل",
      Delete: "حذف",
      sections: {
        users_types: "انواع المستخدمين",
        users: "المستخدمين",
        branches: "الفروع",
        places: "المناطق",
        seats: "الجلسات",
        total_templates: "قوالب الضريبة",
        bills_types: "انواع الفواتير",
        items_categories: "مجموعة الاصناف",
        items: "الاصناف",
        clients: "العملاء",
        invoices: "الفواتير",
        reports: "التقارير",
        permissions: "الصلاحيات"
      }
    },
    all: "الكل",
    actions: "الخيارات",
    status: "الحالة",
    edit: "تعديل",
    add: "اضافة",
    delete: "حذف",
    view: "عرض",
    ar: "عربي",
    en: "انجليزي",
    search: "بحث ...",
    save: "حفظ",
    error: "هناك حطأ ما .",
    are_you_sure: "هل انت متأكد .",
    yes: "نعم",
    no: "لا",
    choose_file: "اختر ملف",
    select: "اختر",
    change_status: "تغيير الحالة",
    login: "تسجيل الدخول",
    email_mobile: "البريد الالكتروني او رقم الهاتف .",
    password: " كلمة المرور",
    Next:"التالي",
    Previous:"السابق",
    loading: "تحميل",
    previous: "السابق",
    next: "التالي",
    login_desc: "قم بتسجيل الدخول إلى لوحة التحكم",
    add_row: "اضافة صف",
    delete_row: "حذف صف",
    download: "تحميل"
  }
};

export let i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "en", // set fallback locale
  messages // set locale messages
});
