<template>
  <section class="content">
    <b-modal
      ref="edit-user"
      id="edit-user"
      :title="
        $t('edit') + ' ' + user_edit_model['name']
      "
      hide-footer
    >
      <div class="d-block">
        <form>
          <b-alert
            :variant="edit_response.status ? 'success' : 'danger'"
            :show="edit_response.show ? true : false"
            >{{ edit_response.message }}</b-alert
          >
          <div class="row clearfix">
            <div class="col-10">
                  <div class="form-group">
                          <label>{{ $t("UsersModule.name") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('UsersModule.name')
                            "
                            v-model="user_edit_model.name"
                          />
                        </div>
                          <div class="form-group">
                          <label>{{ $t("UsersModule.email") }}</label>
                          <input
                            type="email"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('UsersModule.email')
                            "
                            v-model="user_edit_model.email"
                          />
                        </div>
                           <div class="form-group">
                          <label>{{ $t("UsersModule.username") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('UsersModule.username')
                            "
                            v-model="user_edit_model.username"
                          />
                        </div>
                          <div class="form-group">
                          <label>{{ $t("UsersModule.mobile") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('UsersModule.mobile')
                            "
                            v-model="user_edit_model.mobile"
                          />
                        </div>
                         <div class="form-group">
                              <label>{{ $t("UsersModule.type") }}</label>
                              <select class="form-control pt-0 col-md-12" v-model="user_edit_model.user_type">
                                <option value="">{{ $t("select") }}</option>
                                <option
                                  :value="type.id"
                                  v-for="(type, i) in UsersTypes"
                                  :key="i"
                                  >{{ type["name_" + $i18n.locale] }}</option
                                >
                              </select>
                            </div>
                            <div class="form-group">
                              <label>{{ $t("UsersModule.role") }}</label>
                              <select class="form-control pt-0 col-md-12" v-model="user_edit_model.role_id">
                                <option value="">{{ $t("select") }}</option>
                                <option
                                  :value="permissionsGroup.role_id"
                                  v-for="(permissionsGroup, i) in PermissionsGroups"
                                  :key="i"
                                  >{{ permissionsGroup["title_" + $i18n.locale] }}</option
                                >
                              </select>
                            </div>
                               <div class="form-group">
                          <label>{{ $t("UsersModule.password") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-12"
                            :placeholder="
                              $t('UsersModule.password')
                            "
                            v-model="user_edit_model.password"
                          />
                        </div>
                          
            </div>
          </div>
          <button
            class="btn btn-primary"
            @click.prevent="editUsers"
            :disabled="disabled"
          >
            <span v-if="disabled">
              <i class="fa fa-spinner fa-spin" style="margin: 0 3px;"></i>
              {{ $t("loading") }}
              &nbsp; &nbsp; &nbsp;
              {{ edit_progress }}%
            </span>
            <span v-else>{{ $t("save") }}</span>
          </button>
        </form>
      </div></b-modal
    >
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <b-tabs
        pills
        card
        no-key-nav
        content-class="custom-card"
        nav-wrapper-class="custom-nav-card"
      >
        <b-tab no-body :title="$t('all')" active v-if="$can('portal.users.show')">
          <template v-slot:title>
            <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
          </template>
          <b-card-text>
            <b-card no-body class="card">
              <b-card-header>
                <div class="card-tools">
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      class="form-control float-right"
                      :placeholder="$t('search')"
                      v-model="search_key"
                    />
                    <div class="input-group-append">
                      <button
                        type="submit"
                        :disabled="search_disabled"
                        @click.prevent="searchFn"
                        class="btn btn-default"
                      >
                        <i
                          class="fa fa-spinner fa-spin"
                          v-if="search_disabled"
                        ></i>
                        <i class="fas fa-search" v-else></i>
                      </button>
                    </div>
                  </div>
                </div>
              </b-card-header>
              <!-- /.card-header -->
              <b-card-body class="table-responsive p-0">
                <div class="table-responsive">
                  <table class="table table-hover table-custom spacing8">
                    <thead>
                      <tr>
                        <th>{{ $t("UsersModule.id") }}</th>
                        <th>{{ $t("UsersModule.name") }}</th>
                        <th>{{ $t("UsersModule.email") }}</th>
                        <th>{{ $t("UsersModule.mobile") }}</th>
                        <th>{{ $t("UsersModule.type") }}</th>
                        <th class="w60">{{ $t("actions") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(user, i) in Users" :key="i">
                        <td v-text="user.id"></td>
                        <td v-text="user['name']"></td>
                        <td v-text="user.email"></td>
                        <td v-text="user.mobile"></td>
                        <td v-text="user.type['name_' + $i18n.locale]"></td>
                        <td>
                         
                          <button
                            type="button"
                            class="btn btn-sm btn-default ml-1 mr-1"
                            v-tooltip.bottom="$t('delete')"
                            v-if="$can('portal.users.delete')"
                            @click.prevent="deleteUser(user)"
                          >
                            <i class="fa fa-trash text-danger"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-default m-1"
                            v-tooltip.bottom="$t('edit')"
                            v-if="$can('portal.users.edit')"
                            @click.prevent="editUser(user)"
                          >
                            <i class="fa fa-edit text-success"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  
                </div>
              </b-card-body>
              <!-- /.card-body -->
              <b-card-footer class="card-footer clearfix">
                <b-pagination
                  class="m-2"
                  v-model="current_page"
                  :total-rows="pagination.total"
                  :per-page="pagination.per_page"
                  :prev-text="$t('previous')"
                  :next-text="$t('next')"
                  align="right"
                  :first-number="true"
                  :last-number="true"
                ></b-pagination>
              </b-card-footer>
            </b-card> </b-card-text
        ></b-tab>
        <b-tab no-body :title="$t('add')">
          <template v-slot:title>
            <i class="fa fa-list ml-2 mr-2"></i> {{ $t("add") }}
          </template>
          <b-card-text>
            <b-card no-body class="card">
              <b-card-body>
                <div class="body">
                  <form>
                    <b-alert
                      :variant="add_response.status ? 'success' : 'danger'"
                      :show="add_response.show ? true : false"
                      >{{ add_response.message }}</b-alert
                    >
                    <div class="row clearfix">
                      <div class="col-11">
                        <div class="form-group">
                          <label>{{ $t("UsersModule.name") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-6"
                            :placeholder="
                              $t('UsersModule.name')
                            "
                            v-model="user_model.name"
                          />
                        </div>
                          <div class="form-group">
                          <label>{{ $t("UsersModule.email") }}</label>
                          <input
                            type="email"
                            class="form-control col-md-6"
                            :placeholder="
                              $t('UsersModule.email')
                            "
                            v-model="user_model.email"
                          />
                        </div>
                           <div class="form-group">
                          <label>{{ $t("UsersModule.username") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-6"
                            :placeholder="
                              $t('UsersModule.username')
                            "
                            v-model="user_model.username"
                          />
                        </div>
                          <div class="form-group">
                          <label>{{ $t("UsersModule.mobile") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-6"
                            :placeholder="
                              $t('UsersModule.mobile')
                            "
                            v-model="user_model.mobile"
                          />
                        </div>
                         <div class="form-group">
                              <label>{{ $t("UsersModule.type") }}</label>
                              <select class="form-control pt-0 col-md-6" v-model="user_model.user_type">
                                <option value="">{{ $t("select") }}</option>
                                <option
                                  :value="type.id"
                                  v-for="(type, i) in UsersTypes"
                                  :key="i"
                                  >{{ type["name_" + $i18n.locale] }}</option
                                >
                              </select>
                            </div>
                             <div class="form-group">
                              <label>{{ $t("UsersModule.role") }}</label>
                              <select class="form-control pt-0 col-md-6" v-model="user_model.role_id">
                                <option value="">{{ $t("select") }}</option>
                                <option
                                  :value="permissionsGroup.role_id"
                                  v-for="(permissionsGroup, i) in PermissionsGroups"
                                  :key="i"
                                  >{{ permissionsGroup["title_" + $i18n.locale] }}</option
                                >
                              </select>
                            </div>
                               <div class="form-group">
                          <label>{{ $t("UsersModule.password") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-6"
                            :placeholder="
                              $t('UsersModule.password')
                            "
                            v-model="user_model.password"
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      class="btn btn-primary"
                      @click.prevent="addUser"
                      :disabled="disabled"
                    >
                      <span v-if="disabled">
                        <i
                          class="fa fa-spinner fa-spin"
                          style="margin: 0 3px;"
                        ></i>
                        {{ $t("loading") }}
                        &nbsp; &nbsp; &nbsp;
                        {{ add_progress }}%
                      </span>
                      <span v-else>{{ $t("add") }}</span>
                    </button>
                  </form>
                </div>
              </b-card-body>
              <!-- /.card-body -->
            </b-card> </b-card-text
        ></b-tab>
      </b-tabs>
        </div>
      </div>
    </div>
   </section>
</template>

<script>
// import Multiselect from "vue-multiselect";
export default {
  name: "UsersModule",
  // components: { Multiselect },
  data: function() {
    return {
      load: false,
      search_disabled: false,
      Users: [],
      disabled_s: [],
      pagination: {total : 0 ,per_page : 0},
      current_page : 1 ,
      user_model: { id: 0, name: "" , email : "",username : '' , mobile : "" , user_type : '' , password : '' ,role_id : ''},
      user_edit_model: { id: null, name: "", email : "",username : '',mobile : "", user_type : '' , password : '',role_id : ''},
      add_response: { show: false, status: false, message: "" },
      edit_response: { show: false, status: false, message: "" },
      disabled: false,
      add_progress: 0,
      edit_progress: 0,
      search_key: "",
      filters: {},
      UsersTypes : [],
      PermissionsGroups: []
    };
  },
  created() {
    this.filters["page"] = 1;
    this.getUsersTypes();
    this.getPermissionsGroups();
    this.getUsers(this.filters);
  },
   watch:{
    current_page: {
      handler(){
      this.filters["page"] = this.current_page;
      this.getUsers(this.filters);
     },
     deep: false
    }
  },
  methods: {
    getPermissionsGroups(){
       this.axios
        .post("/permissions/groups/get")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.PermissionsGroups = res.result;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("PermissionsGroups", res.message, 1);
          }
        })
        .catch(error => {
          this.handleError("PermissionsGroups", error);
        })
        .finally(() => {
          this.search_disabled = false;
          this.load = true;
        });
    },
      getUsersTypes() {
      this.axios
        .post("/users_types")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.UsersTypes = res.result;
          }else {
            this.handleError("getUsersTypes", res.message, 1);
          }
        })
        .catch(error => {
          if (error) {
            this.handleError("getUsersTypes", error);
          }
        })
        .finally(() => {
          this.search_disabled = false;
          this.load = true;
        });
    },
    editUser(user) {
      this.user_edit_model = JSON.parse(JSON.stringify(user));
      this.user_edit_model.role_id = user.roles[0].id;
      this.$refs["edit-user"].show();
    },
    editUsers() {
      this.edit_response = { show: false, status: false, message: "" };
      this.disabled = true;
      this.axios
        .patch(
          "/users/" + this.user_edit_model.id,
          this.user_edit_model,
          {
            onUploadProgress: progressEvent => {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.edit_progress = percentCompleted;
            }
          }
        )
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            let index = this.Users.findIndex(
              obj => obj.id == this.user_edit_model.id
            );
            this.Users[index] = res.result.object;
            this.edit_response = {
              show: true,
              status: true,
              message: res.message
            };
          } else {
            this.handleError("user_edit", res.message, 1);
          }
        })
        .catch(error => {
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.edit_response = {
              show: true,
              status: false,
              message: res.message
            };
          } else {
            this.handleError("user_edit", error);
          }
        })
        .finally(() => {
          this.edit_progress = 0;
          this.disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    searchFn() {
      this.search_disabled = true;
      this.filters["search_key"] = this.search_key;
      this.getUsers(this.filters);
    },
    getUsers(filters) {
      this.axios
        .post("/users", filters)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.Users = res.result.data;
            this.pagination = res.result.pagination;
          }else {
            this.handleError("getUsers", res.message, 1);
          }
        })
        .catch(error => {
          if (error) {
            this.handleError("getUsers", error);
          }
        })
        .finally(() => {
          this.search_disabled = false;
          this.load = true;
        });
    },
    addUser() {
      this.add_response = { show: false, status: false, message: "" };
      this.disabled = true;
      this.axios
        .post("/users/store", this.user_model, {
          onUploadProgress: progressEvent => {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.add_progress = percentCompleted;
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.user_model = {
              id: 0,
              name: "",
              email: "",
              username: "",
              mobile: "",
              password: "",
              user_type: "",
            };
            this.add_response = {
              show: true,
              status: true,
              message: res.message
            };
            this.Users.unshift(res.result.object);
          } else {
            this.handleError("user_add", res.message, 1);
          }
        })
        .catch(error => {
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.add_response = {
              show: true,
              status: false,
              message: res.message
            };
          } else {
            this.handleError("user_add", error);
          }
        })
        .finally(() => {
          this.add_progress = 0;
          this.disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    deleteUser(user) {
      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            let index = this.findIndex(
              this.Users,
              "id",
              user.id
            );
            this.axios
              .delete("/users/" + user.id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.Users.splice(index, 1);
                } else {
                  this.handleError("user_delete", res.message, 1);
                }
              })
              .catch(error => {
                this.handleError("user_delete", error);
              });
          }
        });
    }
  }
};
</script>
<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->
<style scoped>
.multiselect__tags {
  border: 1px solid #e2e2e2 !important;
  font-size: 14px !important;
  font-weight: 100 !important;
  min-height: 38px !important;
  padding: 0 10px !important;
}
.multiselect__option {
  font-size: 14px !important;
}
.multiselect__tag {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}
.multiselect__tag span {
  color: white !important;
}
.multiselect__placeholder {
  font-size: 14px !important;
  color: #495057;
  margin-bottom: 0 !important;
  padding-top: 7px;
}
.badge {
  margin-left: 3px !important;
}
.order-table {
  color: #77797c;
}
.order-table td {
  padding: 3px;
}
.order-table td:first-child {
  font-weight: bold;
}
.order-table td:nth-child(2) {
  text-align: center;
}
.order-table tr {
  border-bottom: 1px solid #eee;
}
.products-title {
  margin: 0 auto;
  margin-top: 15px;
  padding: 5px 25px;
  background: #17c2d7;
  font-weight: bold;
  border-radius: 5px;
  color: white;
  width: max-content;
}
</style>
