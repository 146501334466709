<template>
  <section class="content">
    <b-modal
    ref="edit-permission"
    id="edit-permission"
    :title="$t('edit')"
    hide-footer
  >
    <div class="d-block">
      <form>
        <b-alert
          :variant="edit_response.status ? 'success' : 'danger'"
          :show="edit_response.show ? true : false"
          >{{ edit_response.message }}</b-alert
        >
        <div class="form-row ">
          <div class="form-group col-md-6">
            <label>{{ $t("PermissionsModule.name") + " | " + $t("ar") }}</label
            ><input type="text" v-model="edit_data.title_en" class="form-control" />
          </div>
          <div class="form-group col-md-6">
            <label>{{ $t("PermissionsModule.name") + " | " + $t("en") }}</label
            ><input type="text" v-model="edit_data.title_ar" class="form-control ltr" />
          </div>
          <div class="col-md-12">
            <label>{{$t('PermissionsModule.title')}}</label>
            <table class="table ">
              <thead class="thead-light">
                <tr>
                  <th>{{$t('PermissionsModule.Section')}}</th>
                  <th>{{$t('PermissionsModule.View')}}</th>
                  <th>{{$t('PermissionsModule.Add')}}</th>
                  <th>{{$t('PermissionsModule.Edit')}}</th>
                  <th>{{$t('PermissionsModule.Delete')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(permission, i) in permissionsArray" :key="i">
                  <td>{{$t('PermissionsModule.sections.'+permission)}}</td>
                  <td>
                    <input
                    type="checkbox"
                    :value="'portal.'+permission+'.show'"
                    style="height: 17px; width: 17px;"
                    v-model="edit_data.permissions"
                    />
                  </td>
                  <td>
                    <input
                    type="checkbox"
                    :value="'portal.'+permission+'.add'"
                    style="height: 17px; width: 17px;"
                    v-model="edit_data.permissions"
                    />
                  </td>
                  <td>
                    <input
                    type="checkbox"
                    :value="'portal.'+permission+'.edit'"
                    style="height: 17px; width: 17px;"
                    v-model="edit_data.permissions"
                    />
                  </td>
                  <td>
                    <input
                    type="checkbox"
                    :value="'portal.'+permission+'.delete'"
                    style="height: 17px; width: 17px;"
                    v-model="edit_data.permissions"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button
          class="btn btn-primary"
          @click.prevent="editPermissions"
          :disabled="disabled"
        >
          <span v-if="disabled">
            <i class="fa fa-spinner fa-spin" style="margin: 0 3px;"></i>
            {{ $t("loading") }}
            &nbsp; &nbsp; &nbsp;
            {{ edit_progress }}%
          </span>
          <span v-else>{{ $t("save") }}</span>
        </button>
      </form>
    </div></b-modal
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <b-tabs
        pills
        card
        no-key-nav
        content-class="custom-card"
        nav-wrapper-class="custom-nav-card"
      >
        <b-tab no-body :title="$t('all')" active v-if="$can('portal.permissions.show')">
          <template v-slot:title>
            <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
          </template>
          <b-card-text>
            <b-card no-body class="card">
              <b-card-header>
                <div class="card-tools">
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      class="form-control float-right"
                      :placeholder="$t('search')"
                      v-model="search_key"
                    />
                    <div class="input-group-append">
                      <button
                        type="submit"
                        :disabled="search_disabled"
                        @click.prevent="searchFn"
                        class="btn btn-default"
                      >
                        <i
                          class="fa fa-spinner fa-spin"
                          v-if="search_disabled"
                        ></i>
                        <i class="fas fa-search" v-else></i>
                      </button>
                    </div>
                  </div>
                </div>
              </b-card-header>
              <!-- /.card-header -->
              <b-card-body class="table-responsive p-0">
                <div class="table-responsive">
                  <table class="table table-hover table-custom spacing8">
                    <thead>
                      <tr>
                        <th>{{ $t("PermissionsModule.id") }}</th>
                        <th>{{ $t("PermissionsModule.name") }}</th>
                        <th class="w60">{{ $t("actions") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(permission_group, i) in PermissionsGroups" :key="i">
                        <td v-text="permission_group.id"></td>
                        <td
                          v-text="permission_group['title_' + $i18n.locale]"
                        ></td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-sm btn-default ml-1 mr-1"
                            v-tooltip.bottom="$t('delete')"
                            v-if="$can('portal.permissions.delete')"
                            @click.prevent="deletePackage(permission_group)"
                          >
                            <i class="fa fa-trash text-danger"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-default m-1"
                            v-tooltip.bottom="$t('edit')"
                            v-if="$can('portal.permissions.edit')"
                            @click.prevent="editPermission(permission_group)"
                          >
                            <i class="fa fa-edit text-success"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
              <!-- /.card-body -->
              <b-card-footer class="card-footer clearfix">
                <b-pagination
                  class="m-2"
                  v-model="current_page"
                  :total-rows="pagination.total"
                  :per-page="pagination.per_page"
                  :prev-text="$t('previous')"
                  :next-text="$t('next')"
                  align="right"
                  :first-number="true"
                  :last-number="true"
                ></b-pagination>
              </b-card-footer>
            </b-card> </b-card-text
        ></b-tab>
        <b-tab no-body :title="$t('add')" v-if="$can('portal.permissions.add')">
          <template v-slot:title>
            <i class="fa fa-list ml-2 mr-2"></i> {{ $t("add") }}
          </template>
          <b-card-text>
            <b-card no-body class="card">
              <b-card-body>
                <div class="body">
                  <form>
                    <b-alert
                      :variant="add_response.status ? 'success' : 'danger'"
                      :show="add_response.show ? true : false"
                      >{{ add_response.message }}</b-alert
                    >
                    <div class="row clearfix">
                      <div class="col-11">
                        <div class="form-group">
                          <label>{{
                            $t("PermissionsModule.name") + " | " + $t("en")
                          }}</label>
                          <input
                            type="text"
                            class="form-control col-md-6"
                            :placeholder="
                              $t('PermissionsModule.name') + ' | ' + $t('en')
                            "
                            v-model="add_data.title_en"
                          />
                        </div>
                        <div class="form-group">
                          <label>{{
                            $t("PermissionsModule.name") + " | " + $t("ar")
                          }}</label>
                          <input
                            type="text"
                            class="form-control col-md-6"
                            :placeholder="
                              $t('PermissionsModule.name') + ' | ' + $t('ar')
                            "
                            v-model="add_data.title_ar"
                          />
                        </div>
                        <div>
                          <label>{{$t('PermissionsModule.title')}}</label>
                          <table class="table ">
                            <thead class="thead-light">
                              <tr>
                                <th>{{$t('PermissionsModule.Section')}}</th>
                                <th>{{$t('PermissionsModule.View')}}</th>
                                <th>{{$t('PermissionsModule.Add')}}</th>
                                <th>{{$t('PermissionsModule.Edit')}}</th>
                                <th>{{$t('PermissionsModule.Delete')}}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(permission, i) in permissionsArray" :key="i">
                                <td>{{$t('PermissionsModule.sections.'+permission)}}</td>
                                <td>
                                  <input
                                  type="checkbox"
                                  :value="'portal.'+permission+'.show'"
                                  style="height: 17px; width: 17px;"
                                  v-model="add_data.permissions"
                                  />
                                </td>
                                <td>
                                  <input
                                  type="checkbox"
                                  :value="'portal.'+permission+'.add'"
                                  style="height: 17px; width: 17px;"
                                  v-model="add_data.permissions"
                                  />
                                </td>
                                <td>
                                  <input
                                  type="checkbox"
                                  :value="'portal.'+permission+'.edit'"
                                  style="height: 17px; width: 17px;"
                                  v-model="add_data.permissions"
                                  />
                                </td>
                                <td>
                                  <input
                                  type="checkbox"
                                  :value="'portal.'+permission+'.delete'"
                                  style="height: 17px; width: 17px;"
                                  v-model="add_data.permissions"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <button
                      class="btn btn-primary"
                      @click.prevent="addPermissionGroup"
                      :disabled="disabled"
                    >
                      <span v-if="disabled">
                        <i
                          class="fa fa-spinner fa-spin"
                          style="margin: 0 3px;"
                        ></i>
                        {{ $t("loading") }}
                        &nbsp; &nbsp; &nbsp;
                        {{ add_progress }}%
                      </span>
                      <span v-else>{{ $t("add") }}</span>
                    </button>
                  </form>
                </div>
              </b-card-body>
              <!-- /.card-body -->
            </b-card> </b-card-text
        ></b-tab>
      </b-tabs>
        </div>
      </div>
    </div>
   </section>
 
</template>

<script>
export default {
  name: "Permissions",
  data: function() {
    return {
      load: false,
      search_disabled: false,
      PermissionsGroups: [],
      Permissions: [],
      image_url: "",
      pagination: {total : 0 ,per_page : 0},
      current_page : 1 ,
      add_data: {
        id: 0,
        title_en: "",
        title_ar: "",
        permissions: []
      },
      add_response: { show: false, status: false, message: "" },
      edit_response: { show: false, status: false, message: "" },
      disabled: false,
      add_progress: 0,
      edit_progress: 0,
      search_key: "",
      permissionsArray : [
        "users_types",
        "users",
        "branches",
        "places",
        "seats",
        "total_templates",
        "bills_types",
        "items_categories",
        "items",
        "clients",
        "invoices",
        "reports",
        "permissions",
      ],
      edit_data: {},
      filters: {},
    };
  },
  created() {
    this.filters["page"] = 1;
    this.getPermissions();
    this.getPermissionsGroups(this.filters);
  },
   watch:{
    current_page: {
      handler(){
      this.filters["page"] = this.current_page;
      this.getPermissionsGroups(this.filters);
     },
     deep: false
    }
  },
  methods: {
    editPermission(permission_group) {
      this.edit_success = this.edit_error = "";
      this.edit_data = { ...this.PermissionsGroups.find(e => e.id == permission_group.id) };
      this.edit_data.permissions = this.edit_data.role.permissions.map(
        e => e.name
      );
      this.$refs["edit-permission"].show();
    },
    editPermissions() {
      this.edit_response = { show: false, status: false, message: "" };
      this.disabled = true;
      this.axios
        .patch(
          "/permissions/groups/" + this.edit_data.id,
          this.edit_data,
          {
            onUploadProgress: progressEvent => {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.edit_progress = percentCompleted;
            }
          }
        )
        .then(response => {
          console.log(response);
          let res = response.data;
          if (res.error_flag == 0) {
            let index = this.PermissionsGroups.findIndex(
              obj => obj.id == this.edit_data.id
            );
            this.PermissionsGroups[index] = res.result.object;
            this.edit_response = {
              show: true,
              status: true,
              message: res.message
            };
          } else {
            this.handleError("PermissionsGroups_edit", res.message, 1);
          }
        })
        .catch(error => {
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.edit_response = {
              show: true,
              status: false,
              message: res.message
            };
          } else {
            this.handleError("PermissionsGroups_edit", error);
          }
        })
        .finally(() => {
          this.edit_progress = 0;
          this.disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    searchFn() {
      this.search_disabled = true;
      this.filters["search_key"] = this.search_key;
      this.getPermissionsGroups(this.filters);
    },
    getPermissionsGroups(filters) {
      this.axios
        .post("/permissions/groups/get", filters)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.PermissionsGroups = res.result.data;
            this.pagination = res.result.pagination;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("PermissionsGroups", res.message, 1);
          }
        })
        .catch(error => {
          this.handleError("PermissionsGroups", error);
        })
        .finally(() => {
          this.search_disabled = false;
          this.load = true;
        });
    },
    getPermissions(filters) {
      this.axios
        .post("/permissions/get", filters)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.Permissions = res.result.permissions;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("Permissions", res.message, 1);
          }
        })
        .catch(error => {
          this.handleError("Permissions", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    addPermissionGroup() {
      this.add_response = { show: false, status: false, message: "" };
      this.disabled = true;
      this.axios
        .post("/permissions/groups/store", this.add_data, {
          onUploadProgress: progressEvent => {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.add_progress = percentCompleted;
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.add_data = {
              id: 0,
              title_en: "",
              title_ar: "",
              permissions: [],
            };
            this.add_response = {
              show: true,
              status: true,
              message: res.message
            };
            this.PermissionsGroups.unshift(res.result.object);
          } else {
            this.handleError("PermissionsGroups_add", res.message, 1);
          }
        })
        .catch(error => {
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.add_response = {
              show: true,
              status: false,
              message: res.message
            };
          } else {
            this.handleError("PermissionsGroups_add", error);
          }
        })
        .finally(() => {
          this.add_progress = 0;
          this.disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    deletePackage(packageSingle) {
      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            let index = this.findIndex(this.packages, "id", packageSingle.id);
            this.axios
              .post("/packages/delete/" + packageSingle.id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.packages.splice(index, 1);
                } else {
                  this.handleError("packages_delete", res.message, 1);
                }
              })
              .catch(error => {
                this.handleError("packages_delete", error);
              });
          }
        });
    }
  }
};
</script>

<style scoped>
.badge {
  margin-left: 3px !important;
}
.order-table {
  color: #77797c;
}
.order-table td {
  padding: 3px;
}
.order-table td:first-child {
  font-weight: bold;
}

.order-table td:nth-child(2) {
  text-align: center;
}

.order-table tr {
  border-bottom: 1px solid #eee;
}

.products-title {
  margin: 0 auto;
  margin-top: 15px;
  padding: 5px 25px;
  background: #17c2d7;
  font-weight: bold;
  border-radius: 5px;
  color: white;
  width: max-content;
}
</style>
