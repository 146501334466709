<template>
  <section class="content">
    <b-modal
      ref="edit-branch"
      id="edit-branch"
      :title="
        $t('edit') + ' ' + branch_edit_model['name_' + $i18n.locale]
      "
      hide-footer
    >
      <div class="d-block">
        <form>
          <b-alert
            :variant="edit_response.status ? 'success' : 'danger'"
            :show="edit_response.show ? true : false"
            >{{ edit_response.message }}</b-alert
          >
          <div class="row clearfix">
            <div class="col-10">
              <div class="form-group">
                <label>{{ $t("BranchesModule.name") + " | " + $t("ar") }}</label>
                <input
                  type="text"
                  class="form-control col-md-11"
                  :placeholder="$t('BranchesModule.name') + ' | ' + $t('ar')"
                  v-model="branch_edit_model.name_ar"
                />
              </div>
              <div class="form-group">
                <label>{{ $t("BranchesModule.name") + " | " + $t("en") }}</label>
                <input
                  type="text"
                  class="form-control col-md-11"
                  :placeholder="$t('BranchesModule.name') + ' | ' + $t('en')"
                  v-model="branch_edit_model.name_en"
                />
              </div>
               <div class="form-group">
                <label>{{ $t("BranchesModule.name") + " | " + $t("en") }}</label>
                <input
                  type="text"
                  class="form-control col-md-11"
                  :placeholder="$t('BranchesModule.printer')"
                  v-model="branch_edit_model.printer"
                />
              </div>
            </div>
          </div>
          <button
            class="btn btn-primary"
            @click.prevent="editBranches"
            :disabled="disabled"
          >
            <span v-if="disabled">
              <i class="fa fa-spinner fa-spin" style="margin: 0 3px;"></i>
              {{ $t("loading") }}
              &nbsp; &nbsp; &nbsp;
              {{ edit_progress }}%
            </span>
            <span v-else>{{ $t("save") }}</span>
          </button>
        </form>
      </div></b-modal
    >
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <b-tabs
        pills
        card
        no-key-nav
        content-class="custom-card"
        nav-wrapper-class="custom-nav-card"
      >
        <b-tab no-body :title="$t('all')" active v-if="$can('portal.branches.show')">
          <template v-slot:title>
            <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
          </template>
          <b-card-text>
            <b-card no-body class="card">
              <b-card-header>
                <div class="card-tools">
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      class="form-control float-right"
                      :placeholder="$t('search')"
                      v-model="search_key"
                    />
                    <div class="input-group-append">
                      <button
                        type="submit"
                        :disabled="search_disabled"
                        @click.prevent="searchFn"
                        class="btn btn-default"
                      >
                        <i
                          class="fa fa-spinner fa-spin"
                          v-if="search_disabled"
                        ></i>
                        <i class="fas fa-search" v-else></i>
                      </button>
                    </div>
                  </div>
                </div>
              </b-card-header>
              <!-- /.card-header -->
              <b-card-body class="table-responsive p-0">
                <div class="table-responsive">
                  <table class="table table-hover table-custom spacing8">
                    <thead>
                      <tr>
                        <th>{{ $t("BranchesModule.id") }}</th>
                        <th>{{ $t("BranchesModule.name") }}</th>
                        <th>{{ $t("BranchesModule.printer") }}</th>
                        <th class="w60">{{ $t("actions") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(branch, i) in Branches" :key="i">
                        <td v-text="branch.id"></td>
                        <td v-text="branch['name_' + $i18n.locale]"></td>
                        <td v-text="branch.printer"></td>
                        <td>
                         
                          <button
                            type="button"
                            class="btn btn-sm btn-default ml-1 mr-1"
                            v-tooltip.bottom="$t('delete')"
                            v-if="$can('portal.branches.delete')"
                            @click.prevent="deleteBranch(branch)"
                          >
                            <i class="fa fa-trash text-danger"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-default m-1"
                            v-tooltip.bottom="$t('edit')"
                            v-if="$can('portal.branches.edit')"
                            @click.prevent="editBranch(branch)"
                          >
                            <i class="fa fa-edit text-success"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  
                </div>
              </b-card-body>
              <!-- /.card-body -->
              <b-card-footer class="card-footer clearfix">
                <b-pagination
                  class="m-2"
                  v-model="current_page"
                  :total-rows="pagination.total"
                  :per-page="pagination.per_page"
                  :prev-text="$t('previous')"
                  :next-text="$t('next')"
                  align="right"
                  :first-number="true"
                  :last-number="true"
                ></b-pagination>
              </b-card-footer>
            </b-card> </b-card-text
        ></b-tab>
        <b-tab no-body :title="$t('add')" v-if="$can('portal.branches.add')">
          <template v-slot:title>
            <i class="fa fa-list ml-2 mr-2"></i> {{ $t("add") }}
          </template>
          <b-card-text>
            <b-card no-body class="card">
              <b-card-body>
                <div class="body">
                  <form>
                    <b-alert
                      :variant="add_response.status ? 'success' : 'danger'"
                      :show="add_response.show ? true : false"
                      >{{ add_response.message }}</b-alert
                    >
                    <div class="row clearfix">
                      <div class="col-11">
                        <div class="form-group">
                          <label>{{ $t("BranchesModule.name") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-6"
                            :placeholder="
                              $t('BranchesModule.name') + ' | ' + $t('ar')
                            "
                            v-model="branch_model.name_ar"
                          />
                        </div>
                        <div class="form-group">
                          <label>{{ $t("BranchesModule.name") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-6"
                            :placeholder="
                              $t('BranchesModule.name') + ' | ' + $t('en')
                            "
                            v-model="branch_model.name_en"
                          />
                        </div>
                           <div class="form-group">
                          <label>{{ $t("BranchesModule.printer") }}</label>
                          <input
                            type="text"
                            class="form-control col-md-6"
                            :placeholder="
                              $t('BranchesModule.printer')
                            "
                            v-model="branch_model.printer"
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      class="btn btn-primary"
                      @click.prevent="addBranch"
                      :disabled="disabled"
                    >
                      <span v-if="disabled">
                        <i
                          class="fa fa-spinner fa-spin"
                          style="margin: 0 3px;"
                        ></i>
                        {{ $t("loading") }}
                        &nbsp; &nbsp; &nbsp;
                        {{ add_progress }}%
                      </span>
                      <span v-else>{{ $t("add") }}</span>
                    </button>
                  </form>
                </div>
              </b-card-body>
              <!-- /.card-body -->
            </b-card> </b-card-text
        ></b-tab>
      </b-tabs>
        </div>
      </div>
    </div>
   </section>
</template>

<script>
// import Multiselect from "vue-multiselect";
export default {
  name: "BranchesModule",
  // components: { Multiselect },
  data: function() {
    return {
      load: false,
      search_disabled: false,
      Branches: [],
      disabled_s: [],
      pagination: {total : 0 ,per_page : 0},
      current_page : 1 ,
      branch_model: { id: 0, name_en: "", name_ar: "" , printer : ""},
      branch_edit_model: { id: null, name_en: "", name_ar: "" , printer : ""},
      add_response: { show: false, status: false, message: "" },
      edit_response: { show: false, status: false, message: "" },
      disabled: false,
      add_progress: 0,
      edit_progress: 0,
      search_key: "",
      filters: {},
      regionFilter: '',
    };
  },
  created() {
    this.filters["page"] = 1;
    this.getBranches(this.filters);
  },
   watch:{
    current_page: {
      handler(){
      this.filters["page"] = this.current_page;
      this.getBranches(this.filters);
     },
     deep: false
    }
  },
  methods: {
    editBranch(branch) {
      this.branch_edit_model = JSON.parse(JSON.stringify(branch));
      this.$refs["edit-branch"].show();
    },
    editBranches() {
      this.edit_response = { show: false, status: false, message: "" };
      this.disabled = true;
      this.axios
        .patch(
          "/branches/" + this.branch_edit_model.id,
          this.branch_edit_model,
          {
            onUploadProgress: progressEvent => {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.edit_progress = percentCompleted;
            }
          }
        )
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            let index = this.Branches.findIndex(
              obj => obj.id == this.branch_edit_model.id
            );
            this.Branches[index] = res.result.object;
            this.edit_response = {
              show: true,
              status: true,
              message: res.message
            };
          } else {
            this.handleError("branch_edit", res.message, 1);
          }
        })
        .catch(error => {
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.edit_response = {
              show: true,
              status: false,
              message: res.message
            };
          } else {
            this.handleError("branch_edit", error);
          }
        })
        .finally(() => {
          this.edit_progress = 0;
          this.disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    searchFn() {
      this.search_disabled = true;
      this.filters["search_key"] = this.search_key;
      this.getBranches(this.filters);
    },
    getBranches(filters) {
      this.axios
        .post("/branches", filters)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.Branches = res.result.data;
            this.pagination = res.result.pagination;
          }else {
            this.handleError("getBranches", res.message, 1);
          }
        })
        .catch(error => {
          if (error) {
            this.handleError("getBranches", error);
          }
        })
        .finally(() => {
          this.search_disabled = false;
          this.load = true;
        });
    },
    addBranch() {
      this.add_response = { show: false, status: false, message: "" };
      this.disabled = true;
      this.axios
        .post("/branches/store", this.branch_model, {
          onUploadProgress: progressEvent => {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.add_progress = percentCompleted;
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.branch_model = {
              id: 0,
              name_en: "",
              name_ar: "",
              printer: ""
            };
            this.add_response = {
              show: true,
              status: true,
              message: res.message
            };
            this.Branches.unshift(res.result.object);
          } else {
            this.handleError("branch_add", res.message, 1);
          }
        })
        .catch(error => {
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.add_response = {
              show: true,
              status: false,
              message: res.message
            };
          } else {
            this.handleError("branch_add", error);
          }
        })
        .finally(() => {
          this.add_progress = 0;
          this.disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    deleteBranch(branch) {
      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            let index = this.findIndex(
              this.Branches,
              "id",
              branch.id
            );
            this.axios
              .delete("/branches/" + branch.id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.Branches.splice(index, 1);
                } else {
                  this.handleError("branch_delete", res.message, 1);
                }
              })
              .catch(error => {
                this.handleError("branch_delete", error);
              });
          }
        });
    }
  }
};
</script>
<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->
<style scoped>
.multiselect__tags {
  border: 1px solid #e2e2e2 !important;
  font-size: 14px !important;
  font-weight: 100 !important;
  min-height: 38px !important;
  padding: 0 10px !important;
}
.multiselect__option {
  font-size: 14px !important;
}
.multiselect__tag {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}
.multiselect__tag span {
  color: white !important;
}
.multiselect__placeholder {
  font-size: 14px !important;
  color: #495057;
  margin-bottom: 0 !important;
  padding-top: 7px;
}
.badge {
  margin-left: 3px !important;
}
.order-table {
  color: #77797c;
}
.order-table td {
  padding: 3px;
}
.order-table td:first-child {
  font-weight: bold;
}
.order-table td:nth-child(2) {
  text-align: center;
}
.order-table tr {
  border-bottom: 1px solid #eee;
}
.products-title {
  margin: 0 auto;
  margin-top: 15px;
  padding: 5px 25px;
  background: #17c2d7;
  font-weight: bold;
  border-radius: 5px;
  color: white;
  width: max-content;
}
</style>
