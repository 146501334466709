<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <b-tabs
        pills
        card
        no-key-nav
        content-class="custom-card"
        nav-wrapper-class="custom-nav-card"
      >
        <b-tab no-body :title="$t('all')" active v-if="$can('portal.invoices.show')">
          <template v-slot:title>
            <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
          </template>
          <b-card-text>
            <b-card no-body class="card">
              <b-card-header>
                <div class="card-tools">
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      class="form-control float-right"
                      :placeholder="$t('search')"
                      v-model="search_key"
                    />
                    <div class="input-group-append">
                      <button
                        type="submit"
                        :disabled="search_disabled"
                        @click.prevent="searchFn"
                        class="btn btn-default"
                      >
                        <i
                          class="fa fa-spinner fa-spin"
                          v-if="search_disabled"
                        ></i>
                        <i class="fas fa-search" v-else></i>
                      </button>
                    </div>
                  </div>
                </div>
              </b-card-header>
              <!-- /.card-header -->
              <b-card-body class="table-responsive p-0">
                <div class="table-responsive">
                  <table class="table table-hover table-custom spacing8">
                    <thead>
                      <tr>
                        <th>{{ $t("InvoicesModule.id") }}</th>
                        <th>{{ $t("InvoicesModule.invoice_no") }}</th>
                        <th>{{ $t("InvoicesModule.client") }}</th>
                        <th>{{ $t("InvoicesModule.total") }}</th>
                        <th>{{ $t("InvoicesModule.vat") }}</th>
                        <th>{{ $t("InvoicesModule.total_amount") }}</th>
                        <th>{{ $t("InvoicesModule.created_at") }}</th>
                        <th class="w60">{{ $t("actions") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(invoice, i) in Invoices" :key="i">
                        <td v-text="invoice.id"></td>
                        <td v-text="invoice.invoice_no"></td>
                        <td v-text="invoice.client['name_' + $i18n.locale]"></td>
                        <td>
                          <span v-if="invoice.total">{{invoice.total}} {{$t("ItemsModule.sar") }}</span>
                          <span v-else>{{$t("ItemsModule.no_price") }}</span>
                        </td>
                        <td>
                          <span v-if="invoice.vat">{{invoice.vat}} {{$t("ItemsModule.sar") }}</span>
                          <span v-else>{{$t("ItemsModule.no_price") }}</span>
                        </td>
                        <td>
                          <span v-if="invoice.total_net">{{invoice.total_net}} {{$t("ItemsModule.sar") }}</span>
                          <span v-else>{{$t("ItemsModule.no_price") }}</span>
                        </td>
                        <td v-text="invoice.created_at"></td>
                        <td>
                          <button
                                 type="button"
                                 class="btn btn-sm btn-default m-1"
                                 v-tooltip.bottom="$t('download')"
                                 @click.prevent="download_invoice(invoice)"
                                 v-if="$can('portal.invoices.show')"
                              >
                                <i class=" fa fa-download"></i>
                              </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  
                </div>
              </b-card-body>
              <!-- /.card-body -->
              <b-card-footer class="card-footer clearfix">
                <b-pagination
                  class="m-2"
                  v-model="current_page"
                  :total-rows="pagination.total"
                  :per-page="pagination.per_page"
                  :prev-text="$t('previous')"
                  :next-text="$t('next')"
                  align="right"
                  :first-number="true"
                  :last-number="true"
                ></b-pagination>
              </b-card-footer>
            </b-card> </b-card-text
        ></b-tab>
        <b-tab no-body :title="$t('InvoicesModule.create')" v-if="$can('portal.invoices.add')">
          <template v-slot:title>
            <i class="fa fa-list ml-2 mr-2"></i> {{ $t('InvoicesModule.create') }}
          </template>
          <b-card-text>
            <b-card no-body class="card">
              <b-card-body>
                <div class="body">
                  <form>
                    <b-alert
                      :variant="add_response.status ? 'success' : 'danger'"
                      :show="add_response.show ? true : false"
                      >{{ add_response.message }}</b-alert
                    >
                    <div class="row clearfix">
                        <div class="col-12">
                          <div class="form-row">
                            <div class="col-md-6">
                              <label>{{ $t("InvoicesModule.client") }}</label>
                              <select class="form-control pt-0 col-md-12" v-model="invoice_model.client_id">
                                <option value="">{{ $t("select") }}</option>
                                <option
                                  :value="client.id"
                                  v-for="(client, i) in Clients"
                                  :key="i"
                                  >{{ client["name_" + $i18n.locale] }}</option
                                >
                              </select>
                            </div>
                            <div class="col-md-6">
                            <label>{{ $t("InvoicesModule.special_code") }}</label>
                            <input
                              type="text"
                              class="form-control col-md-12"
                              :placeholder="$t('InvoicesModule.special_code')"
                              v-model="invoice_model.special_code"
                            />
                         </div>
                          </div>
                      <div class="form-row">
                        <div class="col-md-6">
                            <label>{{ $t("InvoicesModule.details") + " | " + $t("en") }}</label>
                            <textarea
                              type="text"
                              class="form-control col-md-8"
                              :placeholder="$t('InvoicesModule.details') + ' | ' + $t('en')"
                              v-model="invoice_model.details_en"
                            ></textarea>
                         </div>
                          <div class="col-md-6">
                            <label>{{ $t("InvoicesModule.details") + " | " + $t("ar") }}</label>
                            <textarea
                              type="text"
                              class="form-control col-md-8"
                              :placeholder="$t('InvoicesModule.details') + ' | ' + $t('ar')"
                              v-model="invoice_model.details_ar"
                            >
                            </textarea>
                          </div>

                      </div>
                          <div class="form-row">
                        <div class="col-md-6">
                            <label>{{ $t("InvoicesModule.project") + " | " + $t("en") }}</label>
                            <input
                              type="text"
                              class="form-control col-md-8"
                              :placeholder="$t('InvoicesModule.project') + ' | ' + $t('en')"
                               v-model="invoice_model.project_name_en"
                            />
                         </div>
                          <div class="col-md-6">
                            <label>{{ $t("InvoicesModule.project") + " | " + $t("ar") }}</label>
                            <input
                              type="text"
                              class="form-control col-md-8"
                              :placeholder="$t('InvoicesModule.project') + ' | ' + $t('ar')"
                              v-model="invoice_model.project_name_ar"
                            />
                          </div>

                      </div>
                      <div class="form-row">
                            <div class="col-md-6">
                            <label>{{ $t("InvoicesModule.reference_no") }}</label>
                            <input
                              type="text"
                              class="form-control col-md-12"
                              :placeholder="$t('InvoicesModule.reference_no')"
                              v-model="invoice_model.reference_no"
                            />
                         </div>
                         <div class="col-md-6">
                            <label>{{ $t("InvoicesModule.service_date") }}</label>
                            <datepicker v-model="invoice_model.service_date" :format="customFormatter" name="service_date" input-class="form-control col-md-12"></datepicker>
                         </div>
                          </div>
                      <div class="row clearfix">
    <div class="col-md-12">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th class="text-center"> # </th>
            <th class="text-center"> {{$t('InvoicesModule.position')}} </th>
            <th class="text-center"> {{$t('InvoicesModule.total_hours')}} </th>
            <th class="text-center"> {{$t('InvoicesModule.unit_price')}} </th>
            <th class="text-center"> {{$t('InvoicesModule.total')}} </th>
          </tr>
        </thead>
        <tbody>
          <tr  v-for="(item, i) in invoice_model.details" :key="i">
            <td>1</td>
            <td>
              <select class="form-control"  @change.prevent="watch_item_row(item)" v-model="item.item_id">
                <option value="">{{ $t("select") }}</option>
                <option
                  :value="pro.id"
                  v-for="(pro, i) in Items"
                  :key="i"
                  >{{ pro["name_" + $i18n.locale] }}</option
                >
              </select>
            </td>
            <td><input type="number" v-model="item.total_hours" :placeholder="$t('InvoicesModule.forms.enter_total_hours')" class="form-control qty" step="0" min="0"/></td>
            <td><input type="number" v-model="item.unit_price"  :placeholder="$t('InvoicesModule.forms.hour_price')" disabled class="form-control price" step="0.00" min="0"/></td>
            <td><input type="number" :value="item.total_hours*item.unit_price" :placeholder='0.00' class="form-control total" readonly/></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row clearfix">
    <div class="col-md-12">
      <button id="add_row" style="margin:0 2px" class="btn btn-default pull-left" @click.prevent="add_new_row">{{$t('add_row')}}</button>
      <button id='delete_row' style="margin:0 2px" class="pull-right btn btn-default">{{$t('delete_row')}}</button>
    </div>
  </div>
  <div class="row clearfix" style="margin-top:20px">
    <div class="pull-right col-md-4">
      <table class="table table-bordered table-hover" id="tab_logic_total">
        <tbody>
          <tr>
            <th class="text-center">Sub Total</th>
            <td class="text-center"><input type="number" name='sub_total' placeholder='0.00' class="form-control" v-model="invoice_model.total" readonly/></td>
          </tr>
          <tr>
            <th class="text-center">Vat Amount</th>
            <td class="text-center"><input type="number" name='tax_amount' id="tax_amount" placeholder='0.00' class="form-control" v-model="invoice_model.vat" readonly/></td>
          </tr>
          <tr>
            <th class="text-center">Net Total</th>
            <td class="text-center"><input type="number" name='total_amount' id="total_amount" placeholder='0.00' class="form-control" v-model="invoice_model.total_net" readonly/></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
                        </div>
                    </div>

                    <button
                      class="btn btn-primary"
                      @click.prevent="addInvoice"
                      :disabled="disabled"
                    >
                      <span v-if="disabled">
                        <i
                          class="fa fa-spinner fa-spin"
                          style="margin: 0 3px;"
                        ></i>
                        {{ $t("loading") }}
                        &nbsp; &nbsp; &nbsp;
                        {{ add_progress }}%
                      </span>
                      <span v-else>{{ $t("add") }}</span>
                    </button>
                  </form>
                </div>
              </b-card-body>
              <!-- /.card-body -->
            </b-card> </b-card-text
        ></b-tab>
      </b-tabs>
        </div>
      </div>
    </div>
   </section>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
export default {
  name: "ItemsModule",
   components: {
    Datepicker
  },
  data: function() {
    return {
      load: false,
      search_disabled: false,
      Invoices: [],
      disabled_s: [],
      pagination: {total : 0 ,per_page : 0},
      current_page : 1 ,
      invoice_model: { id: 0,reference_no: "",special_code: "",client_id : "", details_en: "", details_ar: "" ,project_name_en: "", project_name_ar: "" , details: [
        {
          item_id: '',
          name_en: '',
          total_hours: '',
          unit_price: ''
        }
      ],
      total: '',
      vat: '',
      total_net: '',
      service_date: ''
      },
      add_response: { show: false, status: false, message: "" },
      edit_response: { show: false, status: false, message: "" },
      disabled: false,
      add_progress: 0,
      edit_progress: 0,
      search_key: "",
      filters: {},
      Clients: [],
      Items: [],
    };
  },
  created() {
    this.filters["page"] = 1;
    this.getClients();
    this.getItems();
    this.getInvoices(this.filters);
  },
   watch:{
    current_page: {
      handler(){
      this.filters["page"] = this.current_page;
      this.getInvoices(this.filters);
     },
     deep: false
    },
    'invoice_model.details': {
      handler(){
          let total = this.invoice_model.details.reduce(function(sum, item) {
            return sum + (item.unit_price * item.total_hours);
          }, 0);
          let vat = total * 0.15;
          let total_net = total + vat;
          this.invoice_model.total = parseFloat(total).toFixed(2);
          this.invoice_model.vat = parseFloat(vat).toFixed(2);
          this.invoice_model.total_net = parseFloat(total_net).toFixed(2);
     },
     deep: true
    }
  },
  methods: {
    customFormatter(date){
      return this.$moment(date).format('MMMM  YYYY');
    },
    download_invoice(invoice){
            this.axios
        .get("/invoices/generate/"+invoice.id,{responseType: 'blob'})
        .then(response => {
            let res = response.data;
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', invoice.project_name_en+'.pdf');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
          if (error) {
            this.handleError("download_invoice", error);
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    add_new_row(){
      this.invoice_model.details.push({
          item_id: '',
          name_en: '',
          total_hours: '',
          unit_price: ''
        });
    },
    watch_item_row(item){
           let get_item = this.Items.find((i)=>{return i.id == item.item_id});
           if(get_item){
              item.unit_price = get_item.unit_price;
           }else{
              item.unit_price = '';
           }
    },
     getItems(){
      this.axios
        .post("/items")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.Items = res.result;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("getClients", res.message, 1);
          }
        })
        .catch(error => {
          this.handleError("getClients", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    getClients(){
      this.axios
        .post("/clients")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.Clients = res.result;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("getClients", res.message, 1);
          }
        })
        .catch(error => {
          this.handleError("getClients", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    editItems() {
      this.edit_response = { show: false, status: false, message: "" };
      this.disabled = true;
      this.axios
        .patch(
          "/items/" + this.item_edit_model.id,
          this.item_edit_model,
          {
            onUploadProgress: progressEvent => {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.edit_progress = percentCompleted;
            }
          }
        )
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            let index = this.Items.findIndex(
              obj => obj.id == this.item_edit_model.id
            );
            this.Items[index] = res.result.object;
            this.edit_response = {
              show: true,
              status: true,
              message: res.message
            };
          } else {
            this.handleError("item_edit", res.message, 1);
          }
        })
        .catch(error => {
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.edit_response = {
              show: true,
              status: false,
              message: res.message
            };
          } else {
            this.handleError("item_edit", error);
          }
        })
        .finally(() => {
          this.edit_progress = 0;
          this.disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    searchFn() {
      this.search_disabled = true;
      this.filters["search_key"] = this.search_key;
      this.getInvoices(this.filters);
    },
    getInvoices(filters) {
      this.axios
        .post("/invoices", filters)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.Invoices = res.result.data;
            this.pagination = res.result.pagination;
          }else {
            this.handleError("getInvoices", res.message, 1);
          }
        })
        .catch(error => {
          if (error) {
            this.handleError("getInvoices", error);
          }
        })
        .finally(() => {
          this.search_disabled = false;
          this.load = true;
        });
    },
    addInvoice(){
            this.add_response = { show: false, status: false, message: "" };
      this.disabled = true;
      this.axios
        .post("/invoices/store", this.invoice_model, {
          onUploadProgress: progressEvent => {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            this.add_progress = percentCompleted;
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.invoice_model = { id: 0,client_id : "", details_en: "", details_ar: "" ,project_name_en: "", project_name_ar: "" , details: [
                {
                  item_id: '',
                  name_en: '',
                  total_hours: '',
                  unit_price: ''
                }
              ],
              total: '',
              vat: '',
              total_net: ''
            };
            this.add_response = {
              show: true,
              status: true,
              message: res.message
            };
            this.Invoices.unshift(res.result.object);
          } else {
            this.handleError("invoice_add", res.message, 1);
          }
        })
        .catch(error => {
          let res = error.response.data;
          if (res.error_flag == 1) {
            this.add_response = {
              show: true,
              status: false,
              message: res.message
            };
          } else {
            this.handleError("invoice_add", error);
          }
        })
        .finally(() => {
          this.add_progress = 0;
          this.disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    }
  }
};
</script>
<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->
<style scoped>
.multiselect__tags {
  border: 1px solid #e2e2e2 !important;
  font-size: 14px !important;
  font-weight: 100 !important;
  min-height: 38px !important;
  padding: 0 10px !important;
}
.multiselect__option {
  font-size: 14px !important;
}
.multiselect__tag {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}
.multiselect__tag span {
  color: white !important;
}
.multiselect__placeholder {
  font-size: 14px !important;
  color: #495057;
  margin-bottom: 0 !important;
  padding-top: 7px;
}
.badge {
  margin-left: 3px !important;
}
.order-table {
  color: #77797c;
}
.order-table td {
  padding: 3px;
}
.order-table td:first-child {
  font-weight: bold;
}
.order-table td:nth-child(2) {
  text-align: center;
}
.order-table tr {
  border-bottom: 1px solid #eee;
}
.products-title {
  margin: 0 auto;
  margin-top: 15px;
  padding: 5px 25px;
  background: #17c2d7;
  font-weight: bold;
  border-radius: 5px;
  color: white;
  width: max-content;
}
</style>
